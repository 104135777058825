@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

html,
:host {
  text-size-adjust: 100%;
  user-select: none; /* Standard syntax */
  -webkit-user-select: none;
  text-decoration-skip: initial;
  -webkit-text-decoration-skip: initial;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(20);
  scrollbar-color: rgba(0, 0, 0, 0) #f8f8f8;
  -webkit-scrollbar-color: rgba(0, 0, 0, 0) #f8f8f8;
}
:where(.css-dev-only-do-not-override-1ux6htg) a {
  text-decoration-skip: initial;
  -webkit-text-decoration-skip: initial;
}
:where(.css-dev-only-do-not-override-1ux6htg) .ant-btn {
  user-select: none; /* Standard syntax */
  -webkit-user-select: none; /* For older Safari versions */
}

@keyframes go651618207 {
  0% {
    height: 0;
    width: 0;
    opacity: 0;
  }
}
@keyframes animate-success-tip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
}
@keyframes animate-x-mark {
  0% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
    margin-top: 26px;
    opacity: 0;
  }
}
@keyframes animate-success-long {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
}
@keyframes animate-success-tip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
}
@keyframes Toastify__slideInRight {
  from {
    transform: translate3d(110%, 0, 0);
    visibility: visible;
  }
}
@keyframes go651618207 {
  0% {
    height: 0;
    width: 0;
    opacity: 0;
  }
}
:where(.css-dev-only-do-not-override-19hovr7).ant-card .ant-card-body {
  padding: 20px;
}
.atp-modal {
  z-index: 1000;
}
